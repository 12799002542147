import React from 'react';
import SEO from '../components/common/seo';
import Card from '../components/Card';

const RubricPage = () => (
  <>
    <SEO title="Home" />
    <div style={{ marginBottom: `1.45rem` }} />
    <div>
      <h1>Categories & Rubric</h1>
      <Card
        body={
          <div>
            <p>
              First and second place will be awarded from the first three categories below.
              Additionally, any presentation will be eligible for the Popular Vote award. You can
              win both a category and the popular vote. A description of each category and the
              rubric is provided below.{' '}
            </p>
            <p>
              You may also submit to the “Wildcard” category for that crazy idea that you just can’t
              wait to share. (note: you will only be eligible for the Popular Vote award)
            </p>
          </div>
        }
      >
        <h2>General Information</h2>
      </Card>

      <Card
        body={
          <div>
            <p>
              In this hackday, we're emphasizing Numerator's focus on Survey by introducing two 10% bonus categories:
              <ul>
                <li><b>Survey - Research Team Enablement</b></li>
                <ul><li>Exploration of ideas to help make our research team more effective</li></ul>
              <li><b>Survey - New Products</b></li>
              <ul><li>We've heard leadership say "The next big idea in the Survey business is out there...", get a team together and go find it. Address a market need or create a product enhancement that can help grow our offering.</li></ul>
              </ul>
            </p>
            <p>
            Although it's not mandatory to complete a hack on these topics, we're encouraging them to explore areas of interest for the business. This is a great opportunity to showcase your creativity and learn something new! Teams that focus their project in this area will receive a <b>10% score bonus.</b>            
            </p>
        </div>
        }
      >
        <h2>✨Late Summer 2024 Bonus!✨</h2>
      </Card>

      <Card
        body={
          <div>
            <p>
              Tackle that crazy idea you have been kicking around. Work on that next Numerator product. 
              Likely 3 days is just enough to scratch the surface and prove the value, but will need follow up.
            </p>
            <ul>
              <li>Impact (Retention, Growth/Opportunity): 40%</li>
              <li>Production Readiness (in production or time/effort to production): 10%</li>
              <li>Creativity: 50%</li>
              <li>BONUS: +20% For teams with at least 2 people</li>
            </ul>
          </div>
        }
      >
        <h2>Big Hack</h2>
      </Card>
      <Card
        body={
          <div>
            <p>
              Address a pain point, improve a system, or deliver a fast win. Whatever you do, get it "done" in the three days.
            </p>
            <ul>
              <li>
                Impact (addressing pain-points, time-saving, increasing transparency,
                fixing gaps, money savings): 10%
              </li>
              <li>Production Readiness (in production or time/effort to production): 40%</li>
              <li>Creativity: 50%</li>
              <li>BONUS: +20% For teams with at least 2 people</li>
            </ul>
          </div>
        }
      >
        <h2>Quick Hack</h2>
      </Card>
      <Card
        body={
          <div>
            <p>
              Explore our rich datasets to bring new and interesting insights to the forefront, 
              improve our current products, or show thought leadership.
            </p>
            <ul>
              <li>Impact (Retention, Growth/Opportunity): 50%</li>
              <li>Creativity: 50%</li>
              <li>BONUS: +20% For teams with at least 2 people</li>
            </ul>
          </div>
        }
      >
        <h2>Data Hack</h2>
      </Card>
      <Card
        body={
          <div>
            <p>Want to spend some time learning a new topic or tech? Got a crazy idea you just want to work on, but doesn't really fit? Sign up and hack.</p>
            <ul>
              <li>Non-Award Category</li>
            </ul>
          </div>
        }
      >
        <h2>Wildcard Hack</h2>
      </Card>
      <Card
        body={
          <div>
            <p>What hack most excited the audience from any of the presentations.</p>
            <ul>
              <li>Not a hack category, but an award that any presentation could win.</li>
            </ul>
          </div>
        }
      >
        <h2>Popular Vote</h2>
      </Card>
    </div>
  </>
);

export default RubricPage;
