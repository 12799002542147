import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ children, body }) => (
  <div className="card-faq">
    <div className="card-body">
      {children}
      {body}
    </div>
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  body: PropTypes.string.isRequired,
};

export default Card;
